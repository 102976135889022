import CanvasPainter from "@/components/businessObjects/quote/AutoDrawings/CanvasPainter";
import { store } from "@/store/Tool.store.js"; // For API calls; didn't use businessObjectStore to not potentially affect shared state

export const autoDrawingStore = {
	errorHandlers: {
		standard: 'A',
		adapterB: 'B',
		adapterC: 'C',
		sideOpenings: false
	},
	
	state: {
		canvasPainter: new CanvasPainter(),
		
		domPrefix: null,
		
		errorHandler: null,
		submittable: false,
		
		curbTypeID: null,
		
		// CSS helpers
		errorClass: 'has-error',
		tooltipClass: 'help-block',
		
		// Canvas helpers
		sFillColor: '#faebcc',
		rFillColor: '#bce8f1',
		
		formFields: {},
		
		quoteCurbData: {},
		quoteCurbID: null,
		
		// Drawing data
		autoDrawing: {
			quoteCurbAutoDrawingID: null,
			
			createdBy: null,
			createDate: null,
			disable: null,
			
			// Applicable to all types
			od1: null,
			od2: null,
			r1: null,
			r2: null,
			r3: null,
			r4: null,
			s1: null,
			s2: null,
			s3: null,
			s4: null,
			
			// Adapters only
			config: 1,
			
			isCenteredLength: 1,
			isCenteredWidth: 1,
			lengthOffset: null,
			widthOffset: null,
			
			// Walls are only for straights & pitched
			topFlange: null,
			braceWidth9999: null,
			hasWalls: false,
			
			pitchUnknown: null,
			
			toggleWall1Return: false,
			wall1ReturnOpenA: null,
			wall1ReturnOpenB: null,
			wall1ReturnOpenWidth: null,
			wall1ReturnOpenHeight: null,
			toggleWall1Supply: false,
			wall1SupplyOpenC: null,
			wall1SupplyOpenD: null,
			wall1SupplyOpenWidth: null,
			wall1SupplyOpenHeight: null,
			
			toggleWall2Return: false,
			wall2ReturnOpenA: null,
			wall2ReturnOpenB: null,
			wall2ReturnOpenWidth: null,
			wall2ReturnOpenHeight: null,
			toggleWall2Supply: false,
			wall2SupplyOpenC: null,
			wall2SupplyOpenD: null,
			wall2SupplyOpenWidth: null,
			wall2SupplyOpenHeight: null,
			
			toggleWall3Return: false,
			wall3ReturnOpenA: null,
			wall3ReturnOpenB: null,
			wall3ReturnOpenWidth: null,
			wall3ReturnOpenHeight: null,
			toggleWall3Supply: false,
			wall3SupplyOpenC: null,
			wall3SupplyOpenD: null,
			wall3SupplyOpenWidth: null,
			wall3SupplyOpenHeight: null,
			
			toggleWall4Return: false,
			wall4ReturnOpenA: null,
			wall4ReturnOpenB: null,
			wall4ReturnOpenWidth: null,
			wall4ReturnOpenHeight: null,
			toggleWall4Supply: false,
			wall4SupplyOpenC: null,
			wall4SupplyOpenD: null,
			wall4SupplyOpenWidth: null,
			wall4SupplyOpenHeight: null,
			
			// Engineering team only
			customOptions: {
				curbHeightOverride: null,
				curbOversize: null,
				topKick: null,
				threeXThreeWidth: null,
				hemHeightOverride: null,
				bottomKick: null,
				gaOverride12GA: null,
				gaOverride14GA: null,
				gaOverride16GA: null,
				tunnelCurb: null
			}
		}
	},
	
	resetData: function () {
		this.errorHandlers.sideOpenings = false;
		
		this.state.errorHandler = null;
		this.resetErrors();
		this.state.submittable = false;
		this.state.curbTypeID = null;
		
		this.state.autoDrawing.quoteCurbAutoDrawingID = null;
		this.state.quoteCurbID = null;
		this.state.quoteCurbData = {};
		
		this.state.autoDrawing.createdBy = null;
		this.state.autoDrawing.createDate = null;
		this.state.autoDrawing.disable = null;
		this.state.autoDrawing.config = 'A';
		
		this.state.autoDrawing.od1 = null;
		this.state.autoDrawing.od2 = null;
		this.state.autoDrawing.r1 = null;
		this.state.autoDrawing.r2 = null;
		this.state.autoDrawing.r3 = null;
		this.state.autoDrawing.r4 = null;
		this.state.autoDrawing.s1 = null;
		this.state.autoDrawing.s2 = null;
		this.state.autoDrawing.s3 = null;
		this.state.autoDrawing.s4 = null;
		
		this.state.autoDrawing.isCenteredLength = 1;
		this.state.autoDrawing.isCenteredWidth = 1;
		this.state.autoDrawing.lengthOffset = null;
		this.state.autoDrawing.widthOffset = null;
		
		this.state.autoDrawing.topFlange = null;
		this.state.autoDrawing.hasWalls = false;
		this.state.autoDrawing.braceWidth9999 = null;
		
		this.state.autoDrawing.pitchUnknown = null;
		
		this.state.autoDrawing.toggleWall1Return = false;
		this.state.autoDrawing.wall1ReturnOpenA = null;
		this.state.autoDrawing.wall1ReturnOpenB = null;
		this.state.autoDrawing.wall1ReturnOpenWidth = null;
		this.state.autoDrawing.wall1ReturnOpenHeight = null;
		this.state.autoDrawing.toggleWall1Supply = false;
		this.state.autoDrawing.wall1SupplyOpenC = null;
		this.state.autoDrawing.wall1SupplyOpenD = null;
		this.state.autoDrawing.wall1SupplyOpenWidth = null;
		this.state.autoDrawing.wall1SupplyOpenHeight = null;
		
		this.state.autoDrawing.toggleWall2Return = false;
		this.state.autoDrawing.wall2ReturnOpenA = null;
		this.state.autoDrawing.wall2ReturnOpenB = null;
		this.state.autoDrawing.wall2ReturnOpenWidth = null;
		this.state.autoDrawing.wall2ReturnOpenHeight = null;
		this.state.autoDrawing.toggleWall2Supply = false;
		this.state.autoDrawing.wall2SupplyOpenC = null;
		this.state.autoDrawing.wall2SupplyOpenD = null;
		this.state.autoDrawing.wall2SupplyOpenWidth = null;
		this.state.autoDrawing.wall2SupplyOpenHeight = null;
		
		this.state.autoDrawing.toggleWall3Return = false;
		this.state.autoDrawing.wall3ReturnOpenA = null;
		this.state.autoDrawing.wall3ReturnOpenB = null;
		this.state.autoDrawing.wall3ReturnOpenWidth = null;
		this.state.autoDrawing.wall3ReturnOpenHeight = null;
		this.state.autoDrawing.toggleWall3Supply = false;
		this.state.autoDrawing.wall3SupplyOpenC = null;
		this.state.autoDrawing.wall3SupplyOpenD = null;
		this.state.autoDrawing.wall3SupplyOpenWidth = null;
		this.state.autoDrawing.wall3SupplyOpenHeight = null;
		
		this.state.autoDrawing.toggleWall4Return = false;
		this.state.autoDrawing.wall4ReturnOpenA = null;
		this.state.autoDrawing.wall4ReturnOpenB = null;
		this.state.autoDrawing.wall4ReturnOpenWidth = null;
		this.state.autoDrawing.wall4ReturnOpenHeight = null;
		this.state.autoDrawing.toggleWall4Supply = false;
		this.state.autoDrawing.wall4SupplyOpenC = null;
		this.state.autoDrawing.wall4SupplyOpenD = null;
		this.state.autoDrawing.wall4SupplyOpenWidth = null;
		this.state.autoDrawing.wall4SupplyOpenHeight = null;
		
		this.state.domPrefix = null;
		this.state.formFields = {};
		
		this.state.autoDrawing.customOptions = {
			curbHeightOverride: null,
			curbOversize: null,
			topKick: null,
			threeXThreeWidth: null,
			hemHeightOverride: null,
			bottomKick: null,
			gaOverride12GA: null,
			gaOverride14GA: null,
			gaOverride16GA: null,
			tunnelCurb: null
		};
		
		// Drawing our standard placeholder diagram as a reset
		this.drawDiagram();
	},
	
	resetErrors: function () {
		let inputs = Object.values(this.state.formFields);
		for (let i = 0; i < inputs.length; i++) {
			this.setErrorStatus(inputs[i], false, '');
		}
	},
	
	load: function (data) {
		this.state.quoteCurbData = data.quoteCurbData;
		this.state.quoteCurbID = data.quoteCurbID;
		this.state.curbTypeID = data.curbTypeID;
		this.state.canvasPainter.setDomID(data.canvasID);
		this.state.domPrefix = data.domPrefix;
		this.errorHandlers.sideOpenings = data.wallOptions;
		this.state.autoDrawing.hasWalls = data.wallOptions;
		this.loadInputs();
		
		store.api(`/quote_curb/get_auto_drawing_data/${ data.quoteCurbID }`).then(result => {
			if (!result.config) {
				return;
			}
			
			this.state.autoDrawing.config = result.config.config;
			this.state.errorHandler = result.config.config;
			
			this.state.autoDrawing.quoteCurbAutoDrawingID = result.config.quote_curb_auto_drawing_id;
			this.state.autoDrawing.od1 = result.config.overall_dimension_1;
			this.state.autoDrawing.od2 = result.config.overall_dimension_2;
			this.state.autoDrawing.r1 = result.config.return_1;
			this.state.autoDrawing.r2 = result.config.return_2;
			this.state.autoDrawing.r3 = result.config.return_3;
			this.state.autoDrawing.r4 = result.config.return_4;
			this.state.autoDrawing.s1 = result.config.supply_1;
			this.state.autoDrawing.s2 = result.config.supply_2;
			this.state.autoDrawing.s3 = result.config.supply_3;
			this.state.autoDrawing.s4 = result.config.supply_4;
			this.state.autoDrawing.hasWalls = result.config.side_openings_9999;
			this.state.autoDrawing.topFlange = result.config.top_flange_9999;
			this.state.autoDrawing.braceWidth9999 = result.config.brace_width_9999;
			this.state.autoDrawing.isCenteredLength = result.config.centered_length;
			this.state.autoDrawing.isCenteredWidth = result.config.centered_width;
			this.state.autoDrawing.lengthOffset = result.config.length_offset;
			this.state.autoDrawing.widthOffset = result.config.width_offset;
			this.state.autoDrawing.pitchUnknown = result.config.pitch_unknown;
			this.state.autoDrawing.createdBy = result.config.created_by;
			this.state.autoDrawing.createDate = result.config.create_date;
			this.state.autoDrawing.disable = result.config.disable;
			
			if (result.config.walls) {
				// Iterate walls and register event handlers
				let count = result.config.walls.length;
				for (let i = 0; i < count; i++) {
					let wall = result.config.walls[i];
					let property_count = wall.wall;

					this.state.autoDrawing['toggleWall' + property_count + 'Return'] = parseFloat(wall['wall_return_opening']);
					this.state.autoDrawing['wall' + property_count + 'ReturnOpenA'] = parseFloat(wall['wall_return_open_a']);
					this.state.autoDrawing['wall' + property_count + 'ReturnOpenB'] = parseFloat(wall['wall_return_open_b']);
					this.state.autoDrawing['wall' + property_count + 'ReturnOpenWidth'] = parseFloat(wall['wall_return_open_width']);
					this.state.autoDrawing['wall' + property_count + 'ReturnOpenHeight'] = parseFloat(wall['wall_return_open_height']);
					
					this.state.autoDrawing['toggleWall' + property_count + 'Supply'] = parseFloat(wall['wall_supply_opening']);
					this.state.autoDrawing['wall' + property_count + 'SupplyOpenC'] = parseFloat(wall['wall_supply_open_c']);
					this.state.autoDrawing['wall' + property_count + 'SupplyOpenD'] = parseFloat(wall['wall_supply_open_d']);
					this.state.autoDrawing['wall' + property_count + 'SupplyOpenWidth'] = parseFloat(wall['wall_supply_open_width']);
					this.state.autoDrawing['wall' + property_count + 'SupplyOpenHeight'] = parseFloat(wall['wall_supply_open_height']);
				}
			}
			
			this.checkForErrors();
		}).then(() => this.drawDiagram());
	},
	
	loadInputs: function () {
		this.state.formFields = {
			od1: document.querySelector(this.state.domPrefix + ' .auto_drawing_od1'),
			od2: document.querySelector(this.state.domPrefix + ' .auto_drawing_od2'),
			r1: document.querySelector(this.state.domPrefix + ' .auto_drawing_r1'),
			r2: document.querySelector(this.state.domPrefix + ' .auto_drawing_r2'),
			r3: document.querySelector(this.state.domPrefix + ' .auto_drawing_r3'),
			r4: document.querySelector(this.state.domPrefix + ' .auto_drawing_r4'),
			s1: document.querySelector(this.state.domPrefix + ' .auto_drawing_s1'),
			s2: document.querySelector(this.state.domPrefix + ' .auto_drawing_s2'),
			s3: document.querySelector(this.state.domPrefix + ' .auto_drawing_s3'),
			s4: document.querySelector(this.state.domPrefix + ' .auto_drawing_s4')
		};
		
		if (this.state.curbTypeID === 1) {
			return;
		}
		
		if (this.state.curbTypeID === 4) {
			this.state.formFields = {
				...this.state.formFields,
				topFlange: document.querySelector(this.state.domPrefix + ' .top_flange'),
				braceWidth9999: document.querySelector(this.state.domPrefix + ' .brace_width')
			};
		}
		
		if ([2, 3].includes(this.state.curbTypeID)) {
			this.state.formFields = {
				...this.state.formFields,
				pitchUnknown: document.querySelector(this.state.domPrefix + ' .pitch_unknown')
			};
		}
		
		this.state.formFields = {
			...this.state.formFields,
			wall1ReturnOpenHeight: document.querySelector(this.state.domPrefix + ' .wall1_return_open_height'),
			wall1SupplyOpenHeight: document.querySelector(this.state.domPrefix + ' .wall1_supply_open_height'),
			wall2ReturnOpenHeight: document.querySelector(this.state.domPrefix + ' .wall2_return_open_height'),
			wall2SupplyOpenHeight: document.querySelector(this.state.domPrefix + ' .wall2_supply_open_height'),
			wall3ReturnOpenHeight: document.querySelector(this.state.domPrefix + ' .wall3_return_open_height'),
			wall3SupplyOpenHeight: document.querySelector(this.state.domPrefix + ' .wall3_supply_open_height'),
			wall4ReturnOpenHeight: document.querySelector(this.state.domPrefix + ' .wall4_return_open_height'),
			wall4SupplyOpenHeight: document.querySelector(this.state.domPrefix + ' .wall4_supply_open_height')
		};
	},
	
	drawDiagram: function () {
		let ctx = this.state.canvasPainter.getContext();
		
		if (ctx) {
			switch (this.state.autoDrawing.config) {
				case this.errorHandlers.standard:
				default:
					this.drawStandardDimensionDiagram(ctx);
					break;
				case this.errorHandlers.adapterB:
					this.drawAdapterDiagramB(ctx);
					break;
				case this.errorHandlers.adapterC:
					this.drawAdapterDiagramC(ctx);
					break;
			}
		}
	},
	
	drawStandardDimensionDiagram: function (ctx) {
		// draw unit boxes
		if (this.state.curbTypeID == 1) {
			this.drawBoxes(ctx, 200, 200, 450, 300);
		} else {
			this.state.canvasPainter.drawBoxes(ctx, 200, 200, 450, 300);
		}
		
		// label existing unit
		ctx.lineWidth = 1;
		this.state.canvasPainter.drawLine(ctx, 600, 150, -30, 50, false);
		this.state.canvasPainter.drawLine(ctx, 630, 150, -30, 0, false);
		this.state.canvasPainter.drawTriangle(ctx, 572, 200, 'd');
		this.state.canvasPainter.lineLabel(ctx, 'EXISTING UNIT', 705, 137, '#e5e8ed', '#000000');
		
		if (this.state.curbTypeID === 1) {
			this.labelNewUnit(ctx);
		}
		
		ctx.fillStyle = '#ffffff';
		ctx.strokeStyle = '#000000';
		ctx.lineWidth = 1;
		
		// return box
		ctx.fillRect(270, 270, 75, 150);
		ctx.strokeRect(270, 270, 75, 150);
		
		// supply box
		ctx.fillRect(440, 270, 75, 130);
		ctx.strokeRect(440, 270, 75, 130);
		
		// write return and supply text
		ctx.fillStyle = '#000000';
		ctx.save();
		ctx.rotate(90 * (-Math.PI / 180));
		ctx.font = '32px Open Sans';
		ctx.fillText('RETURN', -405, 320);
		ctx.fillText('SUPPLY', -394, 490);
		ctx.restore();
		
		// Overall Dimensions
		// OD1
		this.state.canvasPainter.drawLine(ctx, 200, 505, 0, 50, false);
		this.state.canvasPainter.drawLine(ctx, 650, 505, 0, 50, false);
		this.state.canvasPainter.drawLine(ctx, 200, 550, 450, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.od1 ? this.state.autoDrawing.od1 + '"' : "OD1", 420, 535, '#e5e8ed', false);
		
		// OD2
		this.state.canvasPainter.drawLine(ctx, 655, 200, 200, 0, false);
		this.state.canvasPainter.drawLine(ctx, 655, 500, 200, 0, false);
		this.state.canvasPainter.drawLine(ctx, 850, 200, 0, 300, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.od2 ? this.state.autoDrawing.od2 + '"' : "OD2", 850, 400, '#e5e8ed', false);
		
		// Supply
		// S2
		this.state.canvasPainter.drawLine(ctx, 200, 195, 0, -155, false);
		this.state.canvasPainter.drawLine(ctx, 515, 265, 0, -225, false);
		this.state.canvasPainter.drawLine(ctx, 200, 45, 315, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s2 ? this.state.autoDrawing.s2 + '"' : "S2", 400, 30, this.state.sFillColor, false);
		
		// S1
		this.state.canvasPainter.drawLine(ctx, 440, 265, 0, -185, false);
		this.state.canvasPainter.drawLine(ctx, 200, 85, 240, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s1 ? this.state.autoDrawing.s1 + '"' : "S1", 330, 70, this.state.sFillColor, false);
		
		// S4
		this.state.canvasPainter.drawLine(ctx, 520, 400, 280, 0, false);
		this.state.canvasPainter.drawLine(ctx, 795, 200, 0, 200, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s4 ? this.state.autoDrawing.s4 + '"' : "S4", 795, 300, this.state.sFillColor, false);
		
		// S3
		this.state.canvasPainter.drawLine(ctx, 520, 270, 225, 0, false);
		this.state.canvasPainter.drawLine(ctx, 740, 200, 0, 70, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s3 ? this.state.autoDrawing.s3 + '"' : "S3", 740, 220, this.state.sFillColor, false);
		
		// Return
		// R2
		this.state.canvasPainter.drawLine(ctx, 345, 265, 0, -145, false);
		this.state.canvasPainter.drawLine(ctx, 200, 125, 145, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r2 ? this.state.autoDrawing.r2 + '"' : "R2", 275, 110, this.state.rFillColor, false);
		
		// R1
		this.state.canvasPainter.drawLine(ctx, 270, 265, 0, -105, false);
		this.state.canvasPainter.drawLine(ctx, 200, 165, 70, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r1 ? this.state.autoDrawing.r1 + '"' : "R1", 235, 150, this.state.rFillColor, false);
		
		// R4
		this.state.canvasPainter.drawLine(ctx, 195, 200, -140, 0, false);
		this.state.canvasPainter.drawLine(ctx, 265, 420, -210, 0, false);
		this.state.canvasPainter.drawLine(ctx, 60, 200, 0, 220, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r4 ? this.state.autoDrawing.r4 + '"' : "R4", 60, 290, this.state.rFillColor, false);
		
		// R3
		this.state.canvasPainter.drawLine(ctx, 265, 270, -155, 0, false);
		this.state.canvasPainter.drawLine(ctx, 115, 200, 0, 70, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r3 ? this.state.autoDrawing.r3 + '"' : "R3", 115, 220, this.state.rFillColor, false);
	},
	
	drawAdapterDiagramB: function (ctx) {
		// draw unit boxes
		this.drawBoxes(ctx, 200, 200, 450, 300);
		
		// label existing unit
		ctx.lineWidth = 1;
		this.state.canvasPainter.drawLine(ctx, 600, 150, -30, 50, false);
		this.state.canvasPainter.drawLine(ctx, 630, 150, -30, 0, false);
		this.state.canvasPainter.drawTriangle(ctx, 572, 200, 'd');
		this.state.canvasPainter.lineLabel(ctx, 'EXISTING UNIT', 705, 137, '#e5e8ed', '#000000');
		
		this.labelNewUnit(ctx);
		
		ctx.lineWidth = 1;
		ctx.strokeStyle = '#000000';
		ctx.fillStyle = '#ffffff';
		
		// return box
		ctx.fillRect(440, 270, 75, 150);
		ctx.strokeRect(440, 270, 75, 150);
		
		// supply box
		ctx.fillRect(270, 270, 75, 130);
		ctx.strokeRect(270, 270, 75, 130);
		
		// write return and supply text
		ctx.fillStyle = '#000000';
		ctx.save();
		ctx.rotate(90 * (-Math.PI / 180));
		ctx.font = '32px Open Sans';
		ctx.fillText('SUPPLY', -394, 320);
		ctx.fillText('RETURN', -405, 490);
		ctx.restore();
		
		// Overall Dimensions
		// OD1
		this.state.canvasPainter.drawLine(ctx, 200, 505, 0, 50, false);
		this.state.canvasPainter.drawLine(ctx, 650, 505, 0, 50, false);
		this.state.canvasPainter.drawLine(ctx, 200, 550, 450, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.od1 ? this.state.autoDrawing.od1 + '"' : "OD1", 420, 535, '#e5e8ed', false);
		
		// OD2
		this.state.canvasPainter.drawLine(ctx, 655, 200, 200, 0, false);
		this.state.canvasPainter.drawLine(ctx, 655, 500, 200, 0, false);
		this.state.canvasPainter.drawLine(ctx, 850, 200, 0, 300, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.od2 ? this.state.autoDrawing.od2 + '"' : "OD2", 850, 400, '#e5e8ed', false);
		
		// Return
		// R2
		this.state.canvasPainter.drawLine(ctx, 200, 195, 0, -155, false);
		this.state.canvasPainter.drawLine(ctx, 515, 265, 0, -225, false);
		this.state.canvasPainter.drawLine(ctx, 200, 45, 315, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r2 ? this.state.autoDrawing.r2 + '"' : "R2", 400, 30, this.state.rFillColor, false);
		
		// R1
		this.state.canvasPainter.drawLine(ctx, 440, 265, 0, -185, false);
		this.state.canvasPainter.drawLine(ctx, 200, 85, 240, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r1 ? this.state.autoDrawing.r1 + '"' : "R1", 330, 70, this.state.rFillColor, false);
		
		// R4
		this.state.canvasPainter.drawLine(ctx, 520, 420, 280, 0, false);
		this.state.canvasPainter.drawLine(ctx, 795, 200, 0, 220, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r4 ? this.state.autoDrawing.r4 + '"' : "R4", 795, 300, this.state.rFillColor, false);
		
		// R3
		this.state.canvasPainter.drawLine(ctx, 520, 270, 225, 0, false);
		this.state.canvasPainter.drawLine(ctx, 740, 200, 0, 70, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r3 ? this.state.autoDrawing.r3 + '"' : "R3", 740, 220, this.state.rFillColor, false);
		
		// Supply
		// S2
		this.state.canvasPainter.drawLine(ctx, 345, 265, 0, -145, false);
		this.state.canvasPainter.drawLine(ctx, 200, 125, 145, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s2 ? this.state.autoDrawing.s2 + '"' : "S2", 275, 110, this.state.sFillColor, false);
		
		// S1
		this.state.canvasPainter.drawLine(ctx, 270, 265, 0, -105, false);
		this.state.canvasPainter.drawLine(ctx, 200, 165, 70, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s1 ? this.state.autoDrawing.s1 + '"' : "S1", 235, 150, this.state.sFillColor, false);
		
		// S4
		this.state.canvasPainter.drawLine(ctx, 195, 200, -140, 0, false);
		this.state.canvasPainter.drawLine(ctx, 265, 400, -210, 0, false);
		this.state.canvasPainter.drawLine(ctx, 60, 200, 0, 200, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s4 ? this.state.autoDrawing.s4 + '"' : "S4", 60, 290, this.state.sFillColor, false);
		
		// S3
		this.state.canvasPainter.drawLine(ctx, 265, 270, -155, 0, false);
		this.state.canvasPainter.drawLine(ctx, 115, 200, 0, 70, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s3 ? this.state.autoDrawing.s3 + '"' : "S3", 115, 220, this.state.sFillColor, false);
	},
	
	drawAdapterDiagramC: function (ctx) {
		// draw unit boxes
		this.drawBoxes(ctx, 200, 175, 280, 385);
		
		// label existing unit
		ctx.lineWidth = 1;
		this.state.canvasPainter.drawLine(ctx, 190, 121, 30, 50, false);
		this.state.canvasPainter.drawLine(ctx, 160, 121, 30, 0, false);
		this.state.canvasPainter.drawTriangle(ctx, 220, 175, 'd');
		this.state.canvasPainter.lineLabel(ctx, 'EXISTING UNIT', 85, 104, '#e5e8ed', '#000000');
		
		this.labelNewUnit(ctx);
		
		ctx.lineWidth = 1;
		ctx.strokeStyle = '#000000';
		ctx.fillStyle = '#ffffff';
		
		// return box
		ctx.fillRect(240, 220, 75, 150);
		ctx.strokeRect(240, 220, 75, 150);
		
		// supply box
		ctx.fillRect(350, 220, 75, 130);
		ctx.strokeRect(350, 220, 75, 130);
		
		// write return and supply text
		ctx.fillStyle = '#000000';
		ctx.save();
		ctx.rotate(90 * (-Math.PI / 180));
		ctx.font = '32px Open Sans';
		ctx.fillText('RETURN', -355, 290);
		ctx.fillText('SUPPLY', -344, 400);
		ctx.restore();
		
		// Overall Dimensions
		// OD1
		this.state.canvasPainter.drawLine(ctx, 200, 565, 0, 30, false);
		this.state.canvasPainter.drawLine(ctx, 480, 565, 0, 30, false);
		this.state.canvasPainter.drawLine(ctx, 200, 590, 280, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.od1 ? this.state.autoDrawing.od1 + '"' : "OD1", 350, 575, '#e5e8ed', false);
		
		// OD2
		this.state.canvasPainter.drawLine(ctx, 485, 175, 200, 0, false);
		this.state.canvasPainter.drawLine(ctx, 485, 560, 200, 0, false);
		this.state.canvasPainter.drawLine(ctx, 680, 175, 0, 385, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.od2 ? this.state.autoDrawing.od2 + '"' : "OD2", 680, 400, '#e5e8ed', false);
		
		// Supply
		// S2
		this.state.canvasPainter.drawLine(ctx, 425, 215, 0, -80, false);
		this.state.canvasPainter.drawLine(ctx, 425, 140, 55, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s2 ? this.state.autoDrawing.s2 + '"' : "S2", 452, 125, this.state.sFillColor, false);
		
		// S1
		this.state.canvasPainter.drawLine(ctx, 350, 215, 0, -120, false);
		this.state.canvasPainter.drawLine(ctx, 350, 100, 130, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s1 ? this.state.autoDrawing.s1 + '"' : "S1", 410, 85, this.state.sFillColor, false);
		
		// S4
		this.state.canvasPainter.drawLine(ctx, 430, 350, 195, 0, false);
		this.state.canvasPainter.drawLine(ctx, 620, 175, 0, 175, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s4 ? this.state.autoDrawing.s4 + '"' : "S4", 620, 270, this.state.sFillColor, false);
		
		// S3
		this.state.canvasPainter.drawLine(ctx, 430, 220, 140, 0, false);
		this.state.canvasPainter.drawLine(ctx, 565, 175, 0, 45, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.s3 ? this.state.autoDrawing.s3 + '"' : "S3", 565, 183, this.state.sFillColor, false);
		
		// Return
		// R2
		this.state.canvasPainter.drawLine(ctx, 315, 215, 0, -160, false);
		this.state.canvasPainter.drawLine(ctx, 315, 60, 165, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r2 ? this.state.autoDrawing.r2 + '"' : "R2", 365, 45, this.state.rFillColor, false);
		
		// R1
		this.state.canvasPainter.drawLine(ctx, 480, 170, 0, -155, false);
		this.state.canvasPainter.drawLine(ctx, 240, 215, 0, -200, false);
		this.state.canvasPainter.drawLine(ctx, 240, 20, 240, 0, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r1 ? this.state.autoDrawing.r1 + '"' : "R1", 300, 5, this.state.rFillColor, false);
		
		// R4
		this.state.canvasPainter.drawLine(ctx, 195, 175, -140, 0, false);
		this.state.canvasPainter.drawLine(ctx, 235, 370, -180, 0, false);
		this.state.canvasPainter.drawLine(ctx, 60, 175, 0, 195, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r4 ? this.state.autoDrawing.r4 + '"' : "R4", 60, 260, this.state.rFillColor, false);
		
		// R3
		this.state.canvasPainter.drawLine(ctx, 235, 220, -125, 0, false);
		this.state.canvasPainter.drawLine(ctx, 115, 175, 0, 45, true);
		this.state.canvasPainter.lineLabel(ctx, this.state.autoDrawing.r3 ? this.state.autoDrawing.r3 + '"' : "R3", 115, 183, this.state.rFillColor, false);
	},
	
	drawBoxes: function (ctx, x, y, w, h) {
		this.state.canvasPainter.drawBoxes(ctx, x, y, w, h);
		
		// calculate coords for new unit
		if (!this.state.autoDrawing.isCenteredLength || !this.state.autoDrawing.isCenteredWidth) {
			if (!this.state.autoDrawing.isCenteredLength) {
				if (this.state.autoDrawing.lengthOffset < 0) {
					x = x + 40;
				} else {
					x = x - 40;
					w = w + 80;
				}
			}
			if (!this.state.autoDrawing.isCenteredWidth) {
				if (this.state.autoDrawing.widthOffset < 0) {
					y = y + 40;
				} else {
					y = y - 40;
					h = h + 80;
				}
			}
			
			// draw filled new unit
			ctx.fillStyle = 'rgba(11, 57, 84, 0.2)';
			ctx.fillRect(x, y, w, h);
			
			// outline new unit
			ctx.lineWidth = 1;
			ctx.strokeStyle = '#ranklinCDI12' +
				'';
			ctx.strokeRect(x + 1, y + 1, w - 2, h - 2);
		}
	},
	
	labelNewUnit: function (ctx) {
		if (!this.state.autoDrawing.isCenteredWidth || !this.state.autoDrawing.isCenteredLength) {
			ctx.lineWidth = 1;
			
			let offset = 0;
			if (this.state.autoDrawing.isCenteredLength) {
				offset = 0;
			} else if (this.state.autoDrawing.lengthOffset < 0) {
				offset = 40;
			} else {
				offset = -40;
			}
			
			this.state.canvasPainter.drawLine(ctx, 70, 500, 0, -20, false);
			this.state.canvasPainter.drawLine(ctx, 70, 480, 130 + offset, -30, false);
			this.state.canvasPainter.drawTriangle(ctx, 201 + offset, 451, 'r');
			this.state.canvasPainter.lineLabel(ctx, 'NEW UNIT', 70, 500, 'rgba(11, 57, 84, 0.20)', '#0B3954');
		}
	},
	
	updateLabel: function (key, value) {
		this.state.autoDrawing[key] = value;
		this.drawDiagram();
	},
	
	checkForErrors: function () {
		switch (this.state.errorHandler) {
			case this.errorHandlers.standard:
			default:
				this.checkForStandardDimensionErrors();
				break;
			case this.errorHandlers.adapterB:
				this.checkForErrorsConfigB();
				break;
			case this.errorHandlers.adapterC:
				this.checkForErrorsConfigC();
				break;
		}
		
		if (this.errorHandlers.sideOpenings === true) {
			this.checkForSideOpeningErrors();
		}
		
		this.state.submittable = (document.querySelector(`${ this.state.domPrefix } .${ this.state.errorClass }`)) === null;
	},
	
	checkForStandardDimensionErrors: function () {
		let od1 = this.valueToDecimal(this.state.autoDrawing.od1);
		let od2 = this.valueToDecimal(this.state.autoDrawing.od2);
		
		let r1 = this.valueToDecimal(this.state.autoDrawing.r1);
		let r2 = this.valueToDecimal(this.state.autoDrawing.r2);
		let r3 = this.valueToDecimal(this.state.autoDrawing.r3);
		let r4 = this.valueToDecimal(this.state.autoDrawing.r4);
		
		let s1 = this.valueToDecimal(this.state.autoDrawing.s1);
		let s2 = this.valueToDecimal(this.state.autoDrawing.s2);
		let s3 = this.valueToDecimal(this.state.autoDrawing.s3);
		let s4 = this.valueToDecimal(this.state.autoDrawing.s4);
		
		// UNKNOWNOD1		If(Or(UNKNOWNOD1Return < S2Return, UNKNOWNOD1Return < S1Return, UNKNOWNOD1Return < R2Return, UNKNOWNOD1Return < R1Return ), "RED", "White" )
		// OD1
		this.setErrorStatus(this.state.formFields.od1, od1 < s1 || od1 < s2 || od1 < r1 || od1 < r2 || od1 === 0 || isNaN(od1), 'OD1 cannot be less than S1, S2, R1, or R2; cannot be empty');
		
		// UNKNOWNOD2		If(Or(UNKNOWNOD2Return < R3Return,  UNKNOWNOD2Return < R4Return, UNKNOWNOD2Return < S3Return, UNKNOWNOD2Return < S4Return ),"Red", "White")
		// OD2
		this.setErrorStatus(this.state.formFields.od2, od2 < s3 || od2 < s4 || od2 < r3 || od2 < r4 || od2 === 0 || isNaN(od2), 'OD2 cannot be less than S3, S4, R3, or R4; cannot be empty');
		
		// R1		If(Or(RETURN5Return > R2Return, R1Return >UNKNOWNOD1Return), "RED","White")
		// R1
		this.setErrorStatus(this.state.formFields.r1, r1 > r2 || r1 > od1 || (r1 === 0 || isNaN(r1)), 'R1 cannot be greater than R2 or OD1; cannot be empty');
		
		// R2		If(R2Return < R1Return, "Red", "White")
		// R2
		this.setErrorStatus(this.state.formFields.r2, r2 < r1 || (r2 === 0 || isNaN(r2)), 'R2 cannot be less than R1; cannot be empty');
		
		// R3		If(Or(R4Return <  R3Return,  UNKNOWNOD2Return < R3Return),"Red", "White")
		// R3
		this.setErrorStatus(this.state.formFields.r3, r3 > r4 || r3 > od2 || (r3 === 0 || isNaN(r3)), 'R3 cannot be greater than R4 and OD2; cannot be empty');
		
		// R4		If(Or(R4Return <  R3Return,  UNKNOWNOD2Return < R4Return),"Red", "White")
		// R4
		this.setErrorStatus(this.state.formFields.r4, r4 < r3 || r4 > od2 || (r4 === 0 || isNaN(r4)), 'R4 cannot be less than R3 or greater than OD2; cannot be empty');
		
		// Note purposefully flipping result
		// S1		If(Or(And(S1Return <= R2Return, S3Return > R4Return), Or(S1Return > R2Return, S1Return=0, R2Return=0)), "White",  "RED" )
		// S1
		this.setErrorStatus(this.state.formFields.s1, !((s1 <= r2 && s3 > r4) || (s1 > r2)) || (s1 === 0 || isNaN(s1)), 'S1 must be less than or equal to R2 and greater than R4. Otherwise, S1 must be greater than R2; cannot be empty');
		
		// S2		If( S2Return < S1Return, "RED","White")
		// S2
		this.setErrorStatus(this.state.formFields.s2, s2 < s1 || (s2 === 0 || isNaN(s2)), 'S2 must be greater than S1; cannot be empty');
		
		// S3		If(Or(S4Return <  S3Return,  UNKNOWNOD2Return < S3Return),"Red", "White")
		// S3
		this.setErrorStatus(this.state.formFields.s3, s3 > s4 || s3 > od2 || (s3 === 0 || isNaN(s3)), 'S3 cannot be greater than S4 or OD2; cannot be empty');
		
		// S4		If(Or(S4Return <  S3Return,   S4Return > UNKNOWNOD2Return),"Red", "White")
		// S4
		this.setErrorStatus(this.state.formFields.s4, s4 < s3 || s4 > od2 || (s4 === 0 || isNaN(s4)), 'S4 cannot be less than S3 or greater than OD2; cannot be empty');
	
		// Straights
		if (this.state.curbTypeID === 4) {
			let flange = this.valueToDecimal(this.state.autoDrawing.topFlange);
			let braceWidth = this.valueToDecimal(this.state.autoDrawing.braceWidth9999);

			// Need values for flange & brace width
			this.setErrorStatus(this.state.formFields.topFlange, flange === 0 || isNaN(flange), 'Need to input a value for top flange');
			this.setErrorStatus(this.state.formFields.braceWidth9999, braceWidth === 0 || isNaN(braceWidth), 'Need to input a value for brace width');
		}
		
		// Pitched & PMB curbs
		if ([2, 3].includes(this.state.curbTypeID)) {
			let pitchUnknown = this.valueToDecimal(this.state.autoDrawing.pitchUnknown);
			this.setErrorStatus(this.state.formFields.pitchUnknown, pitchUnknown === 0 || isNaN(pitchUnknown), 'Need to input a value for pitch size');
		}
	},
	
	checkForErrorsConfigB: function () {
		let od1 = this.valueToDecimal(this.state.autoDrawing.od1);
		let od2 = this.valueToDecimal(this.state.autoDrawing.od2);
		
		let r1 = this.valueToDecimal(this.state.autoDrawing.r1);
		let r2 = this.valueToDecimal(this.state.autoDrawing.r2);
		let r3 = this.valueToDecimal(this.state.autoDrawing.r3);
		let r4 = this.valueToDecimal(this.state.autoDrawing.r4);
		
		let s1 = this.valueToDecimal(this.state.autoDrawing.s1);
		let s2 = this.valueToDecimal(this.state.autoDrawing.s2);
		let s3 = this.valueToDecimal(this.state.autoDrawing.s3);
		let s4 = this.valueToDecimal(this.state.autoDrawing.s4);
		
		// EXISTINGLENGTH2	If(Or(EXISTINGLENGTH2Return < SUPPLY10Return, EXISTINGLENGTH2Return < SUPPLY9Return, EXISTINGLENGTH2Return < RETURN10Return, EXISTINGLENGTH2Return < RETURN9Return ), "RED", "White" )
		// OD1
		this.setErrorStatus(this.state.formFields.od1, od1 < s1 || od1 < s2 || od1 < r1 || od1 < r2 || (od1 === 0 || isNaN(od1)), 'OD1 cannot be less than S1, S2, R1, or R2; cannot be empty');
		
		// EXISTINGWIDTH2	If(Or(EXISTINGWIDTH2Return < RETURN11Return,  EXISTINGWIDTH2Return < RETURN12Return, EXISTINGWIDTH2Return < SUPPLY11Return, EXISTINGWIDTH2Return < SUPPLY12Return ),"Red", "White")
		// OD2
		this.setErrorStatus(this.state.formFields.od2, od2 < s3 || od2 < s4 || od2 < r3 || od2 < r4 || (od2 === 0 || isNaN(od2)), 'OD2 cannot be less than S3, S4, R3, or R4; cannot be empty');
		
		// RETURN9	If(Or(RETURN9Return > RETURN10Return, RETURN9Return >EXISTINGLENGTH2Return), "RED","White")
		// R1
		this.setErrorStatus(this.state.formFields.r1, r1 > r2 || r1 > od1 || (r1 === 0 || isNaN(r1)), 'R1 cannot be greater than R2 or OD1; cannot be empty');
		
		// RETURN10	If(RETURN10Return < RETURN9Return, "Red", "White")
		// R2
		this.setErrorStatus(this.state.formFields.r2, r2 < r1 || (r2 === 0 || isNaN(r2)), 'R2 cannot be less than R1; cannot be empty');
		
		// RETURN11	If(Or(RETURN12Return <  RETURN11Return,  EXISTINGWIDTH2Return < RETURN11Return),"Red", "White")
		// R3
		this.setErrorStatus(this.state.formFields.r3, r3 > r4 || r3 > od2 || (r3 === 0 || isNaN(r3)), 'R3 cannot be greater than R4 or OD2; cannot be empty');
		
		// RETURN12	If(Or(RETURN12Return <  RETURN11Return,  EXISTINGWIDTH2Return < RETURN12Return),"Red", "White")
		// R4
		this.setErrorStatus(this.state.formFields.r4, r4 < r3 || r4 > od2 || (r4 === 0 || isNaN(r4)), 'R4 cannot be less than R3 or greater than OD2; cannot be empty');
		
		// SUPPLY9	If(SUPPLY9Return > SUPPLY10Return, "RED", "White")
		// S1
		this.setErrorStatus(this.state.formFields.s1, s1 > s2 || (s1 === 0 || isNaN(s1)), 'S1 cannot be greater than S2; cannot be empty');
		
		// Note purposefully flipping result
		// SUPPLY10	If(Or(And(SUPPLY10Return > RETURN9Return, SUPPLY11Return > RETURN12Return), Or(SUPPLY10Return < RETURN9Return, SUPPLY10Return=0, RETURN9Return=0)), "WHITE",  "RED" )
		// S2
		this.setErrorStatus(this.state.formFields.s2, !((s2 > r1 && s3 > r4) || (s2 < r1)) || (s2 === 0 || isNaN(s2)), 'S2 must be greater than R1 and S3 must be greater than R4. Otherwise, S2 must be less than R1; cannot be empty');
		
		// SUPPLY11	If(Or(SUPPLY12Return <  SUPPLY11Return,  EXISTINGWIDTH2Return < SUPPLY11Return),"Red", "White")
		// S3
		this.setErrorStatus(this.state.formFields.s3, s3 > s4 || s3 > od1 || (s3 === 0 || isNaN(s3)), 'S3 cannot be greater than S4 or OD1; cannot be empty');
		
		// SUPPLY12	If(Or(SUPPLY12Return <  SUPPLY11Return,   SUPPLY12Return > EXISTINGWIDTH2Return),"Red", "White")
		// S4
		this.setErrorStatus(this.state.formFields.s4, s4 < s3 || s4 > od2 || (s4 === 0 || isNaN(s4)), 'S4 cannot be less than S3 or greater than OD2; cannot be empty');
	},
	
	checkForErrorsConfigC: function () {
		let od1 = this.valueToDecimal(this.state.autoDrawing.od1);
		let od2 = this.valueToDecimal(this.state.autoDrawing.od2);
		
		let r1 = this.valueToDecimal(this.state.autoDrawing.r1);
		let r2 = this.valueToDecimal(this.state.autoDrawing.r2);
		let r3 = this.valueToDecimal(this.state.autoDrawing.r3);
		let r4 = this.valueToDecimal(this.state.autoDrawing.r4);
		
		let s1 = this.valueToDecimal(this.state.autoDrawing.s1);
		let s2 = this.valueToDecimal(this.state.autoDrawing.s2);
		let s3 = this.valueToDecimal(this.state.autoDrawing.s3);
		let s4 = this.valueToDecimal(this.state.autoDrawing.s4);
		
		// EXISTINGLENGTH3	If(Or(EXISTINGLENGTH3Return < SUPPLY14Return, EXISTINGLENGTH3Return < SUPPLY13Return, EXISTINGLENGTH3Return < RETURN14Return, EXISTINGLENGTH3Return < RETURN13Return ), "RED", "White" )
		// OD1
		this.setErrorStatus(this.state.formFields.od1, od1 < s1 || od1 < s2 || od1 < r1 || od1 < r2 || (od1 === 0 || isNaN(od1)), 'OD1 cannot be less than S1, S2, R1, or R2; cannot be empty');
		
		// EXISTINGWIDTH3	If(Or(EXISTINGWIDTH3Return < RETURN15Return,  EXISTINGWIDTH3Return < RETURN16Return, EXISTINGWIDTH3Return < SUPPLY15Return, EXISTINGWIDTH3Return < SUPPLY16Return ),"Red", "White")
		// OD2
		this.setErrorStatus(this.state.formFields.od2, od2 < s3 || od2 < s4 || od2 < r3 || od2 < r4 || (od2 === 0 || isNaN(od2)), 'OD2 cannot be less than S3, S4, R3, or R4; cannot be empty');
		
		// RETURN14	If(Or(RETURN13Return > RETURN14Return, RETURN14Return >EXISTINGLENGTH3Return), "RED","White")
		// R1
		this.setErrorStatus(this.state.formFields.r1, r1 < r2 || r1 > od1 || (r1 === 0 || isNaN(r1)), 'R1 cannot be less than R2 or greater than OD1; cannot be empty');
		
		// Note flipping result on purpose
		// RETURN13	If(Or(RETURN13Return > RETURN14Return, RETURN13Return> SUPPLY14Return, And( SUPPLY14Return>=RETURN13Return, RETURN16Return <= SUPPLY15Return)),"white","red")
		// R2
		this.setErrorStatus(this.state.formFields.r2, !((r2 > r1 || (r2 > s2 && s2 > 0)) || (r2 <= s2 && r4 <= s3 && r1 > 0)) || (r2 === 0 || isNaN(r2)), 'R2 must be greater than R1 or S2. Otherwise, R2 must be less than or equal to S2 and R4 must be less than or equal to S3; cannot be empty');
		
		// RETURN15	If(Or(RETURN16Return <  RETURN15Return,  EXISTINGWIDTH3Return < RETURN15Return),"Red", "White")
		// R3
		this.setErrorStatus(this.state.formFields.r3, r3 > r4 || r3 > od2 || (r3 === 0 || isNaN(r3)), 'R3 cannot be greater than R4 or OD2; cannot be empty');
		
		// RETURN16	If(Or(RETURN16Return <  RETURN15Return,  EXISTINGWIDTH3Return < RETURN16Return),"Red", "White")
		// R4
		this.setErrorStatus(this.state.formFields.r4, r3 > r4 || r4 > od2 || (r4 === 0 || isNaN(r4)), 'R4 cannot be less than R3 or greater than OD2; cannot be empty');
		
		// Note purposefully flipping result
		// SUPPLY14	If(Or(SUPPLY13Return >= SUPPLY14Return,RETURN13Return>SUPPLY14Return, And( SUPPLY14Return>RETURN13Return, RETURN16Return <= SUPPLY15Return)),"WHITE","RED")
		// S1
		this.setErrorStatus(this.state.formFields.s1, !((s1 > 0 && (s1 <= s2 || s2 < r2)) || (s1 > r2 && s3 >= r4)) || (s1 === 0 || isNaN(s1)), 'S1 must be less than or equal to S2 or less than R2. Otherwise, S1 must be greater than R2 and S3 must be greater than or equal to R4; cannot be empty');
		
		// SUPPLY13	If(SUPPLY13Return > RETURN14Return, "RED", "White")
		// S2
		this.setErrorStatus(this.state.formFields.s2, s2 > r1 || (s2 === 0 || isNaN(s2)), 'S2 cannot be greater than R1; cannot be empty');
		
		// SUPPLY15	If(Or(SUPPLY16Return <  SUPPLY15Return,  EXISTINGWIDTH3Return < SUPPLY15Return),"Red", "White")
		// S3
		this.setErrorStatus(this.state.formFields.s3, s3 > s4 || s3 > od2 || (s3 === 0 || isNaN(s3)), 'S3 cannot be greater than S4 or OD2; cannot be empty');
		
		// SUPPLY16	If(Or(SUPPLY16Return <  SUPPLY15Return,  EXISTINGWIDTH3Return < SUPPLY15Return),"Red", "White")
		// S4
		this.setErrorStatus(this.state.formFields.s4, s4 < s3 || s4 > od2 || (s4 === 0 || isNaN(s4)), 'S4 cannot be less than S3 or greater than OD2; cannot be empty');
	},
	
	checkForSideOpeningErrors: function () {
		let minOpeningBottom = 2;
		let minOpeningTop = 6;
		let curbHeight = parseFloat(this.state.quoteCurbData.base_height);
		
		let values = [
			this.state.autoDrawing.wall1ReturnOpenB,
			this.state.autoDrawing.wall2ReturnOpenB,
			this.state.autoDrawing.wall3ReturnOpenB,
			this.state.autoDrawing.wall4ReturnOpenB,
			this.state.autoDrawing.wall1SupplyOpenD,
			this.state.autoDrawing.wall2SupplyOpenD,
			this.state.autoDrawing.wall3SupplyOpenD,
			this.state.autoDrawing.wall4SupplyOpenD,
		];
		
		// Get highest b or d value to find max height allowable
		let highestFormValue = Math.max(...values);
		
		let subtractor = highestFormValue > minOpeningBottom ? highestFormValue : minOpeningBottom;
		let heightMax = curbHeight - (subtractor + minOpeningTop);
		let errorMessage = 'Height cannot be greater than ' + heightMax;
		
		// All wall heights follow the same logic
		// WALL_RETOPENHEIGHT		If(WALL_RETOPENHEIGHTReturn<= DWletiableCURBHEIGHT-DWletiableMINOPENINGDISTBOTTOM-DWletiableMINOPENINGDISTTOP , "WHITE" , "RED" )
		// WALL_SUPPLYOPENHEIGHT	If(WALL_SUPPLYOPENHEIGHTReturn<= DWletiableCURBHEIGHT-DWletiableMINOPENINGDISTBOTTOM-DWletiableMINOPENINGDISTTOP , "WHITE" , "RED" )
		
		let inputsToIterate = {
			wall1ReturnOpenHeight: this.state.formFields.wall1ReturnOpenHeight,
			wall1SupplyOpenHeight: this.state.formFields.wall1SupplyOpenHeight,
			wall2ReturnOpenHeight: this.state.formFields.wall2ReturnOpenHeight,
			wall2SupplyOpenHeight: this.state.formFields.wall2SupplyOpenHeight,
			wall3ReturnOpenHeight: this.state.formFields.wall3ReturnOpenHeight,
			wall3SupplyOpenHeight: this.state.formFields.wall3SupplyOpenHeight,
			wall4ReturnOpenHeight: this.state.formFields.wall4ReturnOpenHeight,
			wall4SupplyOpenHeight: this.state.formFields.wall4SupplyOpenHeight
		};
		
		for (const [key, input] of Object.entries(inputsToIterate)) {
			let value = this.valueToDecimal(
				this.state.autoDrawing[key]
			);
			
			this.setErrorStatus(input, value > heightMax, errorMessage);
		}
	},
	
	setErrorHandler: function (id) {
		this.state.errorHandler = id;
		this.checkForErrors();
	},
	
	setErrorStatus: function (input, error, errorMessage) {
		// If input is invisible, return
		if (input.offsetParent === null) {
			return;
		}
		
		let errorClass = this.state.errorClass;
		let tooltipClass = this.state.tooltipClass;
		
		let existingError = input.parentElement.parentElement.querySelector(`.${ tooltipClass }`);
		
		if (error) {
			input.parentElement.parentElement.classList.add(errorClass);
			
			let errorNode = document.createElement('span');
			let errorTextNode = document.createTextNode(errorMessage);
			errorNode.appendChild(errorTextNode);
			errorNode.classList.add(tooltipClass);
			
			if (!existingError) {
				input.parentElement.after(errorNode);
			} else {
				existingError.innerHTML = errorMessage;
			}
		} else {
			input.parentElement.parentElement.classList.remove(errorClass, tooltipClass);
			
			if (existingError) {
				existingError.remove();
			}
		}
	},
	
	valueToDecimal: function (value) {
		if (value === null || !value) {
			return 0;
		}
		
		value = value.toString();
		
		if (value.indexOf('/') > -1) {
			if (value.indexOf(' ') >= 0) { // Mixed numbers
				let mixedChunks = value.split(' ');
				
				let wholeNumber = parseFloat(mixedChunks[0]);
				let fraction = mixedChunks[1];
				
				let fractionChunks = fraction.split('/');
				let decimal = parseFloat(parseInt(fractionChunks[0]) / parseInt(fractionChunks[1]));
				
				value = wholeNumber + decimal;
			} else { // Simple fractions
				let chunks = value.split('/');
				value = parseInt(chunks[0]) / parseInt(chunks[1]);
			}
		}
		
		return parseFloat(value);
	},
	
	submit: async function () {
		return await store.api(`/quote_curb/save_auto_drawing/${ this.state.quoteCurbID }`, this.getFormOptions()).then(() => this.resetData());
	},
	
	submitStandard: async function (id) {
		return await store.api(`/quote_curb/save_auto_drawing/${ id }`, {
			'params[standard]' : 1
		});
	},
	
	getFormOptions: function () {
		let options = {
			'params[config]': this.state.autoDrawing.config,
			'params[overall_dimension_1]': this.state.autoDrawing.od1,
			'params[overall_dimension_2]': this.state.autoDrawing.od2,
			'params[return_1]': this.state.autoDrawing.r1,
			'params[return_2]': this.state.autoDrawing.r2,
			'params[return_3]': this.state.autoDrawing.r3,
			'params[return_4]': this.state.autoDrawing.r4,
			'params[supply_1]': this.state.autoDrawing.s1,
			'params[supply_2]': this.state.autoDrawing.s2,
			'params[supply_3]': this.state.autoDrawing.s3,
			'params[supply_4]': this.state.autoDrawing.s4,
			'params[centered_length]': this.state.autoDrawing.isCenteredLength,
			'params[width_offset]': this.state.autoDrawing.widthOffset,
			'params[centered_width]': this.state.autoDrawing.isCenteredWidth,
			'params[length_offset]': this.state.autoDrawing.lengthOffset,
			'params[top_flange_9999]': this.state.autoDrawing.topFlange,
			'params[brace_width_9999]': this.state.autoDrawing.braceWidth9999,
			'params[pitch_unknown]': this.state.autoDrawing.pitchUnknown,
			...this.getWallFormOptions(),
			...this.getCustomFormOptions()
		};
		
		return options;
	},
	
	getWallFormOptions: function () {
		return {
			'params[side_openings_9999]': this.state.autoDrawing.hasWalls,
			'params[wall1_return_opening]': this.state.autoDrawing.toggleWall1Return,
			'params[wall1_return_open_a]': this.state.autoDrawing.wall1ReturnOpenA,
			'params[wall1_return_open_b]': this.state.autoDrawing.wall1ReturnOpenB,
			'params[wall1_return_open_width]': this.state.autoDrawing.wall1ReturnOpenWidth,
			'params[wall1_return_open_height]': this.state.autoDrawing.wall1ReturnOpenHeight,
			'params[wall1_supply_opening]': this.state.autoDrawing.toggleWall1Supply,
			'params[wall1_supply_open_c]': this.state.autoDrawing.wall1SupplyOpenC,
			'params[wall1_supply_open_d]': this.state.autoDrawing.wall1SupplyOpenD,
			'params[wall1_supply_open_width]': this.state.autoDrawing.wall1SupplyOpenWidth,
			'params[wall1_supply_open_height]': this.state.autoDrawing.wall1SupplyOpenHeight,
			'params[wall2_return_opening]': this.state.autoDrawing.toggleWall2Return,
			'params[wall2_return_open_a]': this.state.autoDrawing.wall2ReturnOpenA,
			'params[wall2_return_open_b]': this.state.autoDrawing.wall2ReturnOpenB,
			'params[wall2_return_open_width]': this.state.autoDrawing.wall2ReturnOpenWidth,
			'params[wall2_return_open_height]':this.state.autoDrawing.wall2ReturnOpenHeight,
			'params[wall2_supply_opening]': this.state.autoDrawing.toggleWall2Supply,
			'params[wall2_supply_open_c]': this.state.autoDrawing.wall2SupplyOpenC,
			'params[wall2_supply_open_d]': this.state.autoDrawing.wall2SupplyOpenD,
			'params[wall2_supply_open_width]': this.state.autoDrawing.wall2SupplyOpenWidth,
			'params[wall2_supply_open_height]': this.state.autoDrawing.wall2SupplyOpenHeight,
			'params[wall3_return_opening]': this.state.autoDrawing.toggleWall3Return,
			'params[wall3_return_open_a]': this.state.autoDrawing.wall3ReturnOpenA,
			'params[wall3_return_open_b]': this.state.autoDrawing.wall3ReturnOpenB,
			'params[wall3_return_open_width]': this.state.autoDrawing.wall3ReturnOpenWidth,
			'params[wall3_return_open_height]': this.state.autoDrawing.wall3ReturnOpenHeight,
			'params[wall3_supply_opening]': this.state.autoDrawing.toggleWall3Supply,
			'params[wall3_supply_open_c]': this.state.autoDrawing.wall3SupplyOpenC,
			'params[wall3_supply_open_d]': this.state.autoDrawing.wall3SupplyOpenD,
			'params[wall3_supply_open_width]': this.state.autoDrawing.wall3SupplyOpenWidth,
			'params[wall3_supply_open_height]': this.state.autoDrawing.wall3SupplyOpenHeight,
			'params[wall4_return_opening]': this.state.autoDrawing.toggleWall4Return,
			'params[wall4_return_open_a]': this.state.autoDrawing.wall4ReturnOpenA,
			'params[wall4_return_open_b]': this.state.autoDrawing.wall4ReturnOpenB,
			'params[wall4_return_open_width]': this.state.autoDrawing.wall4ReturnOpenWidth,
			'params[wall4_return_open_height]': this.state.autoDrawing.wall4ReturnOpenHeight,
			'params[wall4_supply_opening]': this.state.autoDrawing.toggleWall4Supply,
			'params[wall4_supply_open_c]': this.state.autoDrawing.wall4SupplyOpenC,
			'params[wall4_supply_open_d]': this.state.autoDrawing.wall4SupplyOpenD,
			'params[wall4_supply_open_width]': this.state.autoDrawing.wall4SupplyOpenWidth,
			'params[wall4_supply_open_height]': this.state.autoDrawing.wall4SupplyOpenHeight
		}
	},
	
	// Engineering custom over-writable options; rigid server-side validation on these fields
	getCustomFormOptions: function () {
		return {
			'params[custom_options][CURBHEIGHTOVERRIDE]': this.state.autoDrawing.customOptions.curbHeightOverride,
			'params[custom_options][CURBOVERSIZE]': this.state.autoDrawing.customOptions.curbOversize,
			'params[custom_options][TOPKICK]': this.state.autoDrawing.customOptions.topKick,
			'params[custom_options][THREEXTHREEWIDTH]': this.state.autoDrawing.customOptions.threeXThreeWidth,
			'params[custom_options][HEMHTOVERRIDE]': this.state.autoDrawing.customOptions.hemHeightOverride,
			'params[custom_options][BOTTOMKICK]': this.state.autoDrawing.customOptions.bottomKick,
			'params[custom_options][GAOVERRIDE12GA]': this.state.autoDrawing.customOptions.gaOverride12GA,
			'params[custom_options][GAOVERRIDE14GA]': this.state.autoDrawing.customOptions.gaOverride14GA,
			'params[custom_options][GAOVERRIDE16GA]': this.state.autoDrawing.customOptions.gaOverride16GA,
			'params[custom_options][TUNNELCURB]': this.state.autoDrawing.customOptions.tunnelCurb
		};
	}
}
